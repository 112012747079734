@import url(https://fonts.googleapis.com/css2?family=Oranienbaum&family=Overpass:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oranienbaum&family=Overpass:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oranienbaum&family=Overpass:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oranienbaum&family=Overpass:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oranienbaum&family=Overpass:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oranienbaum&family=Overpass:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oranienbaum&family=Overpass:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap);
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

/* Pane */
.pane .subtitle {
	font-family: Questrial;
	font-size: 36px;
	letter-spacing: 5px;
	color: white;
	-webkit-font-feature-settings: "smcp", "c2sc";
	        font-feature-settings: "smcp", "c2sc";
	font-variant: all-small-caps;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	line-height: 2;
}
.pane .logoName {
	display: flex;
	flex-direction: row !important;
	justify-content: center;
	align-items: center;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	grid-gap: 25px;
	gap: 25px;
}

/* Intro */
.homeIntro { 
	padding: 75px;
	display: flex;
	flex-direction: column;
	grid-gap: 75px;
	gap: 75px;
}
.introImage {
	display: flex;
	flex-direction: row;
	grid-gap: 75px;
	gap: 75px;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.introImage:nth-of-type(2) {
	flex-wrap: wrap-reverse;
}
.introImage img {
	max-height: 300px;
	object-fit: contain;
	width: 35%;
	min-width: 200px;
	max-width: 400px;
}
.introText {
	max-width: 500px;
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
}

/* Team */
.homeTeam {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.homepfp {
	width: 26%;
	margin: 3%;
	border-radius: 100%;
}
.carouselText {
	font-family: Questrial;
	font-size: 32px;
	-webkit-font-feature-settings: "smcp", "c2sc";
	        font-feature-settings: "smcp", "c2sc";
	font-variant: all-small-caps;
	letter-spacing: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 50px;
}
.carouselImages {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.carouselImages img {
	min-width: 110px;
	max-width: 200px;
}
.carousel {
	width: 90%;
	max-width: 1100px;
	padding: 50px 75px;
}

/* Events */
.homeEvents {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	grid-gap: 75px;
	gap: 75px;
	padding: 100px;
}
.eventsContent {
	display: flex;
	flex-direction: column;
	max-width: 800px;
	grid-gap: 30px;
	gap: 30px;
	padding-bottom: 50px;
}
.eventsItem {
	display: flex;
	flex-direction: row;
	grid-gap: 9%;
	gap: 9%;
	align-items: center;
	justify-content: center;
	/* flex-wrap: wrap; */
}
.eventsItem img {
	width: 30%;
	object-fit: fill;
	border-radius: 100%;
	object-fit: cover;
	min-width: 150px;
}
.eventsTitle {
	font-family: Overpass;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 24px;
	font-weight: 600;
}
.eventsItem .borderBlue {
	outline: 15px solid #ACB4C6;
}
.eventsItem .borderPink {
	outline: 15px solid #E2C0C0;
}
.eventsItem .borderNavy {
	outline: 15px solid #3C4862;
}
.eventsText {
	display: flex;
	flex-direction: column;
	grid-gap: 25px;
	gap: 25px;
	width: 60%;
	max-width: 400px;
}
@media (max-width: 700px) {
	.eventsContent {
		grid-gap: 75px;
		gap: 75px;
	}
	.eventsItem {
		flex-direction: column;
		grid-gap: 50px;
		gap: 50px;
	}
	.eventsItem:nth-of-type(2) {
		flex-direction: column-reverse;
	}
	.eventsItem img {
		width: 80%;
		object-fit: fill;
		border-radius: 100%;
		object-fit: cover;
		max-width: 200px;
	}
	.eventsText {
		width: 100%;
	}
}

/* Resources */
.homeResources {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 75px;
	grid-gap: 50px;
	gap: 50px;
	background-color: #D9D9D9;
}
.folder {
	display: flex;
	flex-direction: row;
	max-width: 900px;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	grid-gap: 75px;
	gap: 75px;
}
.resourcesText {
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
	width: 45%;
	min-width: 300px;
	justify-content: center;
	align-items: center;
}
.resourcesText .textTitle, .resourcesText .textSubtitle {
	text-align: left;
	width: 100%;
}
.resourcesMedia {
	display: flex;
	flex-direction: column;
	grid-gap: 60px;
	gap: 60px;
	align-items: center;
	justify-content: center;
	width: 45%;
}
.resourcesMedia img {
	border-radius: 30px;
	outline: 7px solid #EDDFB8;
	width: 100%;
}

/* Alumni */
.homeAlumni {
	display: flex;
	flex-direction: column;
	padding: 100px;
	grid-gap: 50px;
	gap: 50px;
	align-items: center;
	justify-content: center;
}
.alumIconMap {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	max-width: 800px;
	justify-content: center;
	align-items: center;
	grid-gap: 50px;
	gap: 50px;
	padding: 25px 0px 75px 0px;
}
.alumniIcon img{
	width: 150px;
	border-radius: 100%;
}

@media (max-width: 890px) {
	.pane .logoName {
		flex-direction: column !important;
	}
	.pane .subtitle, .pane .slogan{
		text-align: center;
		line-height: 1.5;
		padding-bottom: 20px;
		width: 100%;
		font-size: 28px;
		letter-spacing: 3px;
	}
	.pane .title {
		font-size: 48px;
		text-align: center;
	}
	.pane img {
		width: 150px;
	}
	.homepfp {
		width: 35%;
	}
	.resourcesMedia {
		width: 100%;
		max-width: 400px;
	}
	.resourcesText {
		width: 70%;
	}
	.resourcesText .textTitle, .resourcesText .textSubtitle {
		text-align: center;
	}
}

@media (max-width: 700px) {
	.homeAlumni {
		padding: 75px;
	}
	.alumIconMap {
		grid-gap: 40px;
		gap: 40px;
	}
	.alumniIcon img {
		width: 100px;
	}
	.homepfp {
		width: 50%;
	}
}
.alumLogos{
	display: flex;
	flex-direction: column;
	padding: 75px;
	grid-gap: 50px;
	gap: 50px;
	justify-content: center;
	align-items: center;
}
.alumni-cont{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	grid-gap: 75px;
	gap: 75px;
	max-width: 1000px;
	justify-content: center;
	align-items: center;
}
.alumni-cont img{
	height: 50px;
}

@media (max-width: 750px) {
    .alumni-cont img {
        max-width: 90%;
        object-fit: contain;
    }
}
/* Pane */
.mission {
    font-family: Overpass;
    font-size: 16px;
    color: white;
    width: 60%;
    max-width: 550px;
    text-align: center;
    border-top: 3px solid white;
    border-bottom: 3px solid white;
    padding: 0px 20px 20px 20px;
}
.missionTitle {
    font-family: Questrial;
    font-size: 28px;
    -webkit-font-feature-settings: "smcp", "c2sc";
            font-feature-settings: "smcp", "c2sc";
    font-variant: all-small-caps;
    letter-spacing: 2px;
    line-height: 2.5;
}

/* Profiles */
.team {
    padding: 75px 25px 25px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 50px;
    gap: 50px
}
.teamProfiles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 50px;
    gap: 50px;
    justify-content: center;
    align-items: flex-start;
    max-width: 1000px;
}
.profile {
    display: flex;
    flex-direction: column;
    width: 45%;
    padding: 40px;
    align-items: center;
    justify-content:first baseline;
    background-color: #E9E9E9;
    grid-gap: 30px;
    gap: 30px;
    max-width: 400px;
    border-radius: 40px;
    /* min-height: 425px; */
}
.profile .pfp {
    width: 60%;
    border-radius: 100%;
}
.linkedin {
    width: 40px;
    border-radius: 25%;
    margin-left: 16px;
    margin-bottom: 15px;
}
.profileText {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    text-align: center;
}
.profile .normText {
    text-align: center;
    font-size: 15px;
}
.profile .subText {
    font-size: 26px;
    line-height: 1;
    letter-spacing: 2px;
}
.profile .textCaption {
    font-size: 18px;
}
/* Coffee Chats */
.aboutChats {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 75px;
    gap: 75px;
    padding: 75px 50px 125px 50px;
}
.chatsContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 10%;
    gap: 10%;
    max-width: 900px;
    align-items: center;
}
.chatsContent img {
    width: 40%;
    object-fit: contain;
}
.chatsText {
    display: flex;
    flex-direction: column;
    grid-gap: 25px;
    gap: 25px;
    line-height: 1.2;
    width: 50%;
}
@media (max-width: 800px) {
    .mission {
        width: 100%;
    }
    .profile {
        width: 80%;
    }
    .chatsContent {
        display: flex;
        flex-direction: column;
        grid-gap: 50px;
        gap: 50px;
    }
    .chatsContent img {
        width: 80%;
        object-fit: contain;
        max-width: 400px;
    }
    .chatsText {
        display: flex;
        flex-direction: column;
        grid-gap: 25px;
        gap: 25px;
        line-height: 1.2;
        width: 85%;
    }
}
.materials {
    display: flex;
    flex-direction: column;
    padding: 75px;
    align-items: center;
    justify-content: center;
    text-align: center; /*REMOVE WHEN MATIERALS ARE ADDED*/
}
.materialsContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    padding: 50px 0px;
    grid-gap: 5%;
    gap: 5%;
}
.materials img {
    width: 100%;
    border-radius: 15px;
}
.resource {
    width: 350px;
    padding: 25px 0px;
    display: flex;
    flex-direction: column;
    grid-gap: 25px;
    gap: 25px;
}
.resource .textHeading {
    line-height: 1;
}
.resource .subText {
    letter-spacing: 2px;
}
.resourceText {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}
.resource .normText {
    text-align: center;
}
.materials .borderBlue {
	outline: 5px solid #ACB4C6;
}
.materials .borderPink {
	outline: 5px solid #E2C0C0;
}
.materials .borderYellow {
	outline: 5px solid #EDDFB8;
}
.materials .borderNavy {
	outline: 5px solid #3C4862;
}
.keepUp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #D2D2D2;
    padding: 25px 50px;
    grid-gap: 25px;
    gap: 25px;
    flex-wrap: wrap;
}
.questions {
    display: flex;
    flex-direction: row;
    padding: 75px;
    padding-top: 0px;
    grid-gap: 75px;
    gap: 75px;
    justify-content: center;
    align-items: center;
}
.questionsText {
    display: flex;
    flex-direction: column;
    grid-gap: 25px;
    gap: 25px;
    width: 55%;
    max-width: 450px;
}
.questions img {
    width: 40%;
    max-width: 350px;
}
@media (max-width: 850px) {
    .questions{
        flex-wrap: wrap-reverse;
    }
    .questionsText, .questions img {
        width: 85%;
    }
}
.schedule {
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
    justify-content: center;
    align-items: center;
    padding: 75px;
}
.schedule .textCaption {
    text-align: center;
    max-width: 400px;
    font-size: 20px;
}
.schedule .textTitle {
    line-height: 2;
}
.calendar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 25px 40px 25px;
    border-bottom: 2px solid black;
    max-width: 650px;
}
.calendar:nth-child(7), .calendar:nth-child(11){
    border-bottom: none;
}
.month {
    font-family: Overpass;
    font-size: 44px;
    font-weight: 700;
    letter-spacing: 5px;
    min-width: 130px;
}
.calendarEvent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
}
.monthEventDesc {
    display: flex;
    flex-direction: column;
    justify-content: left;
}
.monthEvent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.date {
    min-width: 100px;
    text-align: left;
    padding: 0px 5px;
    color: grey;
}
.event {
    text-align: left;
    padding: 0px 20px;
    width: 100%;
}
.eventDesc {
    padding: 20px;
    line-height: 1.3;
}
.descDisplay {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

@media (max-width: 700px) {
    .calendar {
        flex-direction: column;
        grid-gap: 25px;
        gap: 25px;
    }
    .monthEvent {
        flex-wrap: wrap;
    }
    .event {
        width: 95%;
        padding: 0px;
    }
    .date {
        padding: 0px;
    }
    .eventDesc {
        padding: 15px 0px ;
    }
    .schedule {
        padding: 75px 50px;
    }
}
.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 75px;
    grid-gap: 75px;
    gap: 75px;
}
.contactContent {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    grid-gap: 75px;
    gap: 75px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.contactText {
    display: flex;
    flex-direction: column;
    grid-gap: 25px;
    gap: 25px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}
.contactText img {
    width: 40px;
    border-radius: 25%;
}
.contact .textTitle, .contact .textSubtitle {
    text-align: left;
}
.contactInfo {
    display: flex;
    flex-direction: column;
    grid-gap: 13px;
    gap: 13px;
}
.contactInfo a {
    display: flex;
    flex-direction: row;
    grid-gap: 25px;
    gap: 25px;
    align-items: center;    
}
.contactInfo .normText {
    text-align: left;
}
.contactInfo a .normText:visited, .contactInfo a:visited{
    text-decoration: none;
}
.contactInfo a .normText:hover, .contactInfo a:hover {
    text-decoration: none;
    font-weight: 600;  
}
.analyst {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    background-color: #EDDFB8;
    border-radius: 50px;
    padding: 35px;
}
.analystForm {
    display: flex;
    flex-direction: row;
    grid-gap: 25px;
    gap: 25px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.analystForm img {
    width: 35%;
    object-fit: contain;
    min-width: 150px;
}
.analystText {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    grid-gap: 30px;
    gap: 30px;
    width: 55%;
    min-width: 200px;
}
@media (max-width: 645px) {
    .analystText {
        width: 90%;
    }
}
.analystForm .contentButton {
    align-self: center;
}
.analyst .normText {
    text-align: left;
}
.programs {
    padding: 0px 50px 100px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
}
.programs .textSubtitle {
    max-width: 600px;
    padding-bottom: 25px;
}
.programGrid {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-gap: 50px;
    gap: 50px;
}
.programTeam {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    border-radius: 40px;
    padding: 30px;
    grid-gap: 25px;
    gap: 25px;
    font-family: Overpass;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
}
.programTeam img {
    width: 60%;
    align-self: center;
    max-height: 175px;
    object-fit: contain;
}
.programs .borderBlue {
	outline: 7px solid #ACB4C6;
}
.programs .borderPink {
	outline: 7px solid #E2C0C0;
}
.programs .borderYellow {
	outline: 7px solid #EDDFB8;
}
.programs .borderNavy {
	outline: 7px solid #3C4862;
}
.partners {
    display: flex;
    flex-direction: column;
    padding: 75px 10px;
    align-items: center;
    justify-content: center;
    text-align: center; 
    grid-gap: 100px; 
    gap: 100px;
    width: 100%;
}
.collab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 850px;
}
.logoImg{
    background-color: #3C4862;
    width: 100%;
    padding: 20px;
}
.logoImg img{
    max-height: 75px;
}
.collabContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 5%;
    gap: 5%;
}
.collabContent img{
    width: 35%;
}
.partnerDesc {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
    padding: 15px 0px;
}
@media(max-width: 750px){
    .collabContent {
        flex-direction: column;
    }
    .collabContent img{
        width: 70%;
    }
    .partnerDesc {
        align-items: center;
        padding: 20px;
    }
    .partnerDesc .normText {
        text-align: center;
    }
}
