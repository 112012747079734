@import'https://fonts.googleapis.com/css2?family=Oranienbaum&family=Overpass:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap';

.materials {
    display: flex;
    flex-direction: column;
    padding: 75px;
    align-items: center;
    justify-content: center;
    text-align: center; /*REMOVE WHEN MATIERALS ARE ADDED*/
}
.materialsContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    padding: 50px 0px;
    gap: 5%;
}
.materials img {
    width: 100%;
    border-radius: 15px;
}
.resource {
    width: 350px;
    padding: 25px 0px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.resource .textHeading {
    line-height: 1;
}
.resource .subText {
    letter-spacing: 2px;
}
.resourceText {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.resource .normText {
    text-align: center;
}
.materials .borderBlue {
	outline: 5px solid #ACB4C6;
}
.materials .borderPink {
	outline: 5px solid #E2C0C0;
}
.materials .borderYellow {
	outline: 5px solid #EDDFB8;
}
.materials .borderNavy {
	outline: 5px solid #3C4862;
}
.keepUp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #D2D2D2;
    padding: 25px 50px;
    gap: 25px;
    flex-wrap: wrap;
}
.questions {
    display: flex;
    flex-direction: row;
    padding: 75px;
    padding-top: 0px;
    gap: 75px;
    justify-content: center;
    align-items: center;
}
.questionsText {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 55%;
    max-width: 450px;
}
.questions img {
    width: 40%;
    max-width: 350px;
}
@media (max-width: 850px) {
    .questions{
        flex-wrap: wrap-reverse;
    }
    .questionsText, .questions img {
        width: 85%;
    }
}