@import'https://fonts.googleapis.com/css2?family=Oranienbaum&family=Overpass:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap';

/* Pane */
.pane .subtitle {
	font-family: Questrial;
	font-size: 36px;
	letter-spacing: 5px;
	color: white;
	font-variant: all-small-caps;
	width: fit-content;
	line-height: 2;
}
.pane .logoName {
	display: flex;
	flex-direction: row !important;
	justify-content: center;
	align-items: center;
	width: fit-content;
	gap: 25px;
}

/* Intro */
.homeIntro { 
	padding: 75px;
	display: flex;
	flex-direction: column;
	gap: 75px;
}
.introImage {
	display: flex;
	flex-direction: row;
	gap: 75px;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.introImage:nth-of-type(2) {
	flex-wrap: wrap-reverse;
}
.introImage img {
	max-height: 300px;
	object-fit: contain;
	width: 35%;
	min-width: 200px;
	max-width: 400px;
}
.introText {
	max-width: 500px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

/* Team */
.homeTeam {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.homepfp {
	width: 26%;
	margin: 3%;
	border-radius: 100%;
}
.carouselText {
	font-family: Questrial;
	font-size: 32px;
	font-variant: all-small-caps;
	letter-spacing: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 50px;
}
.carouselImages {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.carouselImages img {
	min-width: 110px;
	max-width: 200px;
}
.carousel {
	width: 90%;
	max-width: 1100px;
	padding: 50px 75px;
}

/* Events */
.homeEvents {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 75px;
	padding: 100px;
}
.eventsContent {
	display: flex;
	flex-direction: column;
	max-width: 800px;
	gap: 30px;
	padding-bottom: 50px;
}
.eventsItem {
	display: flex;
	flex-direction: row;
	gap: 9%;
	align-items: center;
	justify-content: center;
	/* flex-wrap: wrap; */
}
.eventsItem img {
	width: 30%;
	object-fit: fill;
	border-radius: 100%;
	object-fit: cover;
	min-width: 150px;
}
.eventsTitle {
	font-family: Overpass;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 24px;
	font-weight: 600;
}
.eventsItem .borderBlue {
	outline: 15px solid #ACB4C6;
}
.eventsItem .borderPink {
	outline: 15px solid #E2C0C0;
}
.eventsItem .borderNavy {
	outline: 15px solid #3C4862;
}
.eventsText {
	display: flex;
	flex-direction: column;
	gap: 25px;
	width: 60%;
	max-width: 400px;
}
@media (max-width: 700px) {
	.eventsContent {
		gap: 75px;
	}
	.eventsItem {
		flex-direction: column;
		gap: 50px;
	}
	.eventsItem:nth-of-type(2) {
		flex-direction: column-reverse;
	}
	.eventsItem img {
		width: 80%;
		object-fit: fill;
		border-radius: 100%;
		object-fit: cover;
		max-width: 200px;
	}
	.eventsText {
		width: 100%;
	}
}

/* Resources */
.homeResources {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 75px;
	gap: 50px;
	background-color: #D9D9D9;
}
.folder {
	display: flex;
	flex-direction: row;
	max-width: 900px;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 75px;
}
.resourcesText {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 45%;
	min-width: 300px;
	justify-content: center;
	align-items: center;
}
.resourcesText .textTitle, .resourcesText .textSubtitle {
	text-align: left;
	width: 100%;
}
.resourcesMedia {
	display: flex;
	flex-direction: column;
	gap: 60px;
	align-items: center;
	justify-content: center;
	width: 45%;
}
.resourcesMedia img {
	border-radius: 30px;
	outline: 7px solid #EDDFB8;
	width: 100%;
}

/* Alumni */
.homeAlumni {
	display: flex;
	flex-direction: column;
	padding: 100px;
	gap: 50px;
	align-items: center;
	justify-content: center;
}
.alumIconMap {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	max-width: 800px;
	justify-content: center;
	align-items: center;
	gap: 50px;
	padding: 25px 0px 75px 0px;
}
.alumniIcon img{
	width: 150px;
	border-radius: 100%;
}

@media (max-width: 890px) {
	.pane .logoName {
		flex-direction: column !important;
	}
	.pane .subtitle, .pane .slogan{
		text-align: center;
		line-height: 1.5;
		padding-bottom: 20px;
		width: 100%;
		font-size: 28px;
		letter-spacing: 3px;
	}
	.pane .title {
		font-size: 48px;
		text-align: center;
	}
	.pane img {
		width: 150px;
	}
	.homepfp {
		width: 35%;
	}
	.resourcesMedia {
		width: 100%;
		max-width: 400px;
	}
	.resourcesText {
		width: 70%;
	}
	.resourcesText .textTitle, .resourcesText .textSubtitle {
		text-align: center;
	}
}

@media (max-width: 700px) {
	.homeAlumni {
		padding: 75px;
	}
	.alumIconMap {
		gap: 40px;
	}
	.alumniIcon img {
		width: 100px;
	}
	.homepfp {
		width: 50%;
	}
}