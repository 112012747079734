@import'https://fonts.googleapis.com/css2?family=Oranienbaum&family=Overpass:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap';

.alumLogos{
	display: flex;
	flex-direction: column;
	padding: 75px;
	gap: 50px;
	justify-content: center;
	align-items: center;
}
.alumni-cont{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 75px;
	max-width: 1000px;
	justify-content: center;
	align-items: center;
}
.alumni-cont img{
	height: 50px;
}

@media (max-width: 750px) {
    .alumni-cont img {
        max-width: 90%;
        object-fit: contain;
    }
}