@import'https://fonts.googleapis.com/css2?family=Oranienbaum&family=Overpass:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap';

.partners {
    display: flex;
    flex-direction: column;
    padding: 75px 10px;
    align-items: center;
    justify-content: center;
    text-align: center; 
    gap: 100px;
    width: 100%;
}
.collab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 850px;
}
.logoImg{
    background-color: #3C4862;
    width: 100%;
    padding: 20px;
}
.logoImg img{
    max-height: 75px;
}
.collabContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5%;
}
.collabContent img{
    width: 35%;
}
.partnerDesc {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 15px;
    padding: 15px 0px;
}
@media(max-width: 750px){
    .collabContent {
        flex-direction: column;
    }
    .collabContent img{
        width: 70%;
    }
    .partnerDesc {
        align-items: center;
        padding: 20px;
    }
    .partnerDesc .normText {
        text-align: center;
    }
}