@import'https://fonts.googleapis.com/css2?family=Oranienbaum&family=Overpass:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap';

/* Pane */
.mission {
    font-family: Overpass;
    font-size: 16px;
    color: white;
    width: 60%;
    max-width: 550px;
    text-align: center;
    border-top: 3px solid white;
    border-bottom: 3px solid white;
    padding: 0px 20px 20px 20px;
}
.missionTitle {
    font-family: Questrial;
    font-size: 28px;
    font-variant: all-small-caps;
    letter-spacing: 2px;
    line-height: 2.5;
}

/* Profiles */
.team {
    padding: 75px 25px 25px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px
}
.teamProfiles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    align-items: flex-start;
    max-width: 1000px;
}
.profile {
    display: flex;
    flex-direction: column;
    width: 45%;
    padding: 40px;
    align-items: center;
    justify-content:first baseline;
    background-color: #E9E9E9;
    gap: 30px;
    max-width: 400px;
    border-radius: 40px;
    /* min-height: 425px; */
}
.profile .pfp {
    width: 60%;
    border-radius: 100%;
}
.linkedin {
    width: 40px;
    border-radius: 25%;
    margin-left: 16px;
    margin-bottom: 15px;
}
.profileText {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
}
.profile .normText {
    text-align: center;
    font-size: 15px;
}
.profile .subText {
    font-size: 26px;
    line-height: 1;
    letter-spacing: 2px;
}
.profile .textCaption {
    font-size: 18px;
}
/* Coffee Chats */
.aboutChats {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 75px;
    padding: 75px 50px 125px 50px;
}
.chatsContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10%;
    max-width: 900px;
    align-items: center;
}
.chatsContent img {
    width: 40%;
    object-fit: contain;
}
.chatsText {
    display: flex;
    flex-direction: column;
    gap: 25px;
    line-height: 1.2;
    width: 50%;
}
@media (max-width: 800px) {
    .mission {
        width: 100%;
    }
    .profile {
        width: 80%;
    }
    .chatsContent {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
    .chatsContent img {
        width: 80%;
        object-fit: contain;
        max-width: 400px;
    }
    .chatsText {
        display: flex;
        flex-direction: column;
        gap: 25px;
        line-height: 1.2;
        width: 85%;
    }
}