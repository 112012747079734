@import'https://fonts.googleapis.com/css2?family=Oranienbaum&family=Overpass:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap';

.schedule {
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
    padding: 75px;
}
.schedule .textCaption {
    text-align: center;
    max-width: 400px;
    font-size: 20px;
}
.schedule .textTitle {
    line-height: 2;
}
.calendar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 25px 40px 25px;
    border-bottom: 2px solid black;
    max-width: 650px;
}
.calendar:nth-child(7), .calendar:nth-child(11){
    border-bottom: none;
}
.month {
    font-family: Overpass;
    font-size: 44px;
    font-weight: 700;
    letter-spacing: 5px;
    min-width: 130px;
}
.calendarEvent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.monthEventDesc {
    display: flex;
    flex-direction: column;
    justify-content: left;
}
.monthEvent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.date {
    min-width: 100px;
    text-align: left;
    padding: 0px 5px;
    color: grey;
}
.event {
    text-align: left;
    padding: 0px 20px;
    width: 100%;
}
.eventDesc {
    padding: 20px;
    line-height: 1.3;
}
.descDisplay {
    width: fit-content;
}

@media (max-width: 700px) {
    .calendar {
        flex-direction: column;
        gap: 25px;
    }
    .monthEvent {
        flex-wrap: wrap;
    }
    .event {
        width: 95%;
        padding: 0px;
    }
    .date {
        padding: 0px;
    }
    .eventDesc {
        padding: 15px 0px ;
    }
    .schedule {
        padding: 75px 50px;
    }
}