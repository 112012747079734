@import'https://fonts.googleapis.com/css2?family=Oranienbaum&family=Overpass:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap';

.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 75px;
    gap: 75px;
}
.contactContent {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    gap: 75px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.contactText {
    display: flex;
    flex-direction: column;
    gap: 25px;
    min-width: fit-content;
}
.contactText img {
    width: 40px;
    border-radius: 25%;
}
.contact .textTitle, .contact .textSubtitle {
    text-align: left;
}
.contactInfo {
    display: flex;
    flex-direction: column;
    gap: 13px;
}
.contactInfo a {
    display: flex;
    flex-direction: row;
    gap: 25px;
    align-items: center;    
}
.contactInfo .normText {
    text-align: left;
}
.contactInfo a .normText:visited, .contactInfo a:visited{
    text-decoration: none;
}
.contactInfo a .normText:hover, .contactInfo a:hover {
    text-decoration: none;
    font-weight: 600;  
}
.analyst {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    background-color: #EDDFB8;
    border-radius: 50px;
    padding: 35px;
}
.analystForm {
    display: flex;
    flex-direction: row;
    gap: 25px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.analystForm img {
    width: 35%;
    object-fit: contain;
    min-width: 150px;
}
.analystText {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    gap: 30px;
    width: 55%;
    min-width: 200px;
}
@media (max-width: 645px) {
    .analystText {
        width: 90%;
    }
}
.analystForm .contentButton {
    align-self: center;
}
.analyst .normText {
    text-align: left;
}
.programs {
    padding: 0px 50px 100px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.programs .textSubtitle {
    max-width: 600px;
    padding-bottom: 25px;
}
.programGrid {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 50px;
}
.programTeam {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    border-radius: 40px;
    padding: 30px;
    gap: 25px;
    font-family: Overpass;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
}
.programTeam img {
    width: 60%;
    align-self: center;
    max-height: 175px;
    object-fit: contain;
}
.programs .borderBlue {
	outline: 7px solid #ACB4C6;
}
.programs .borderPink {
	outline: 7px solid #E2C0C0;
}
.programs .borderYellow {
	outline: 7px solid #EDDFB8;
}
.programs .borderNavy {
	outline: 7px solid #3C4862;
}